import { Link } from 'gatsby'
import React from 'react'
import { Container } from 'react-bootstrap'
import './Sitemap.scss'
import links from './links'

const Sitemap: React.FC = () => {
  return (
    <div id="Sitemap">
      <Container>
        <h1>
          User
          <br />
          Sitemap
        </h1>

        <div className="links">
          {Object.keys(links).map(section => {
            return (
              <section key={section}>
                <h6>{section}</h6>
                {links[section].map(l => {
                  return (
                    <Link to={l.link} key={l.name}>
                      {l.name}
                    </Link>
                  )
                })}
              </section>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default Sitemap
