import { HelpCenterLink } from '../Footer/HelpCenter/HelpCenter'
import * as l from '../internal-links'

const links: { [index: string]: HelpCenterLink[] } = {
  Home: [
    {
      name: 'About Us',
      link: l.ABOUT_US,
    },
    {
      name: 'Certifications',
      link: l.CERTIFICATIONS,
    },
    {
      name: 'Privacy Policy',
      link: l.PRIVACY_POLICY,
    },
    {
      name: 'Terms and Conditions',
      link: l.TERMS,
    },
  ],

  Games: [
    {
      name: 'Rummy',
      link: l.RUMMY,
    },
  ],

  Support: [
    {
      name: 'Contact Us',
      link: l.CONTACT_US,
    },
  ],
}

export default links
