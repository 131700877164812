import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SitemapContent from '../components/Sitemap/Sitemap'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { SITEMAP } from '../components/internal-links'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'User Sitemap',
    url: SITEMAP,
  },
]

const Sitemap: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
    >
      <SEO title="Mega | Sitemap" breadcrumbsSchema={breadcrumbs} />
      <SitemapContent />
    </Layout>
  )
}

export default Sitemap
